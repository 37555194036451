<template>
  <div class="mt-30">
    <Carousel/>

    <v-container>
      <v-row class="d-flex justify-space-around">
            <v-col cols="12" md="12">
              <CountSection/>
            </v-col>
        </v-row>

      <v-row class="d-flex justify-space-around">
        <v-col v-for="(sl, i) in services" :key="i" cols="12" md="4" sm="12">
          <CardImage
              :description="sl.description"
              :imageSrc="sl.img"
              :title="sl.title"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-around">
        <v-col md="12">
          <div class="site-heading">
            <h2>nuestros servicios</h2>
            <h3>Toda una amplia gama de servicios acorde a sus necesidades</h3>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(ds, i) in services" :key="i" cols="12" md="4" xs="12">
          <CardService :data="ds" :showDescription="false"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import {Carousel, CardImage, CardService, CountSection} from "@/components";

export default {
  name: "HomeView",
  components: {
    Carousel,
    CardImage,
    CardService,
    CountSection
  },
  data: () => ({
    services: [
            {
                id: 1,
                title: "Jardinería",
                description: "Mantenimiento y asistencia técnica para el control de tus jardines, canchas y todo tipo de área verde, ya sea de resguardo, protección o conservación.",
                list: [
                    "Mantenimiento de áreas verdes.",
                    "Poda de árboles.",
                    "Limpieza de terreno.s",
                    "Barrido de áreas verdes.",
                    "Riego de áreas verdes.",
                    "Aplicación de insecticida.",
                    "Mantenimiento de zona."
                ],
                img: require("@/assets/img/1913_960/3.jpg"),
                img_about: require("@/assets/img/1913_960/4.jpg"),
                gallery: [
                require("@/assets/img/1913_960/1.jpg"),
                require("@/assets/img/1913_960/2.jpg"),
                require("@/assets/img/1913_960/3.jpg"),
                require("@/assets/img/1913_960/4.jpg"),
                ],
                icon: "fas fa-leaf",
            },
            {
                id: 2,
                title: "Plomería",
                description: "Reparación y mantenimiento a tuberías y accesorios de sistemas de desagüe, abastecimiento de agua y drenaje en instalaciones domésticas, comerciales, industriales y redes externas.",
                list: [
                    "Cambio de mangueras",
                    "Cambio de llaves",
                    "Cambio de accesorios para WC",
                    "Fregadero",
                    "Llaves",
                    "Lavabo y/o lava manos"
                ],
                img: require("@/assets/img/1913_960/11.jpg"),
                img_about: require("@/assets/img/1913_960/10.jpg"),
                gallery: [
                require("@/assets/img/1913_960/5.jpg"),
                require("@/assets/img/1913_960/6.jpg"),
                require("@/assets/img/1913_960/7.jpg"),
                require("@/assets/img/1913_960/8.jpg"),
                ],
                icon: "fas fa-toilet"
            },
            {
                id: 3,
                title: "Electricidad",
                description: "Ejecución de planos de cableado eléctrico para el buen funcionamiento de la iluminación, del sistema de intercomunicación, instalación de aparatos eléctricos, instalaciones fijas y equipos para sistemas de alarmas y de otros sistemas eléctricos",
                list: [
                    "Cambio de contactos",
                    "Cambio de centro de carga",
                    "Cambio de pastillas termincas",
                    "Cambio de soquets",
                    "Corrección de fallo electrico menor"
                ],
                img: require("@/assets/img/1913_960/23.jpg"),
                img_about: require("@/assets/img/1913_960/21.jpg"),
                gallery: [
                require("@/assets/img/1913_960/18.jpg"),
                require("@/assets/img/1913_960/19.jpg"),
                require("@/assets/img/1913_960/20.jpg"),
                require("@/assets/img/1913_960/21.jpg"),
                ],
                icon: "fas fa-bolt",
            },
            {
                id: 4,
                title: "Seguridad industrial",
                description: "Venta de EPP y asistencia, supervisión en procedimientos para la prevención y limitación de riesgos, así como la protección contra accidentes y siniestros capaces de producir daños o perjuicios a las personas, bienes o al medio ambiente, derivados de la actividad industrial o de la utilización, funcionamiento y mantenimiento de las instalaciones, equipos o productos industriales.",
                list: [
                    "Suministro de todo tipo de equipo de protección y seguridad industrial",
                    "Productos de seguridad",
                    "Servicios"
                ],
                img: require("@/assets/img/1913_960/28.jpg"),
                img_about: require("@/assets/img/1913_960/26.jpg"),
                gallery: [
                require("@/assets/img/1913_960/24.jpg"),
                require("@/assets/img/1913_960/25.jpg"),
                require("@/assets/img/1913_960/27.jpg"),
                require("@/assets/img/1913_960/28.jpg"),
                ],
                icon: "fas fa-hard-hat",
            },
            {
                id: 5,
                title: "Servicios profesionales",
                description: "Servicios especializados en la administracion y funcionamiento de empresas, servicios sanitarios, de consultoría, servicios legales (tanto de asesoramiento como de representación en juicio), servicios de ingeniería, auditoría, etc.",
                list: [
                    "Administración de personal",
                    "Administración de nómina",
                    "Asesoria contable",
                    "Asesoría administrativa",
                    "Asesoría en sistemas y organización",
                    "Reclutamiento y selección de personal",
                    "Higuiene y seguridad",
                    "Limpieza"
                ],
                img: require("@/assets/img/1913_960/31.jpg"),
                img_about: require("@/assets/img/1913_960/34.jpg"),
                gallery: [
                require("@/assets/img/1913_960/36.jpg"),
                require("@/assets/img/1913_960/30.jpg"),
                require("@/assets/img/1913_960/29.jpg"),
                require("@/assets/img/1913_960/41.jpg"),
                ],
                icon: "fas fa-file-contract",
            },
            {
                id: 6,
                title: "Fotocopiado",
                description: "Damos solución a tus problemas gracias a nuestro servicio de atención al cliente y con la garantía de contar con técnicos especialistas. Diseñamos planes de venta y mantenimiento.",
                list: [
                    "Copiadoras de bajo volumen",
                    "Copiadoras de alto volumen",
                    "Copiados y escaner de tamaños especiales",
                    "Copiadoras e impresoras blanco y negro",
                    "Copiadoras e impresoras a color"
                ],
                img: require("@/assets/img/1913_960/43.jpg"),
                img_about: require("@/assets/img/1913_960/47.jpg"),
                gallery: [
                require("@/assets/img/1913_960/42.jpg"),
                require("@/assets/img/1913_960/44.jpg"),
                require("@/assets/img/1913_960/48.jpg"),
                require("@/assets/img/1913_960/46.jpg"),
                ],
                icon: "fas fa-print",
            },
            {
                id: 7,
                title: "Asfálto",
                description: "Fabricación y venta de emulsiones asfálticas y de rompimientos",
                list: [
                    "Asfalto RC-2250",
                    "Emulsión asfaltica",
                    "Asfalto MC-30",
                    "Asfalto MC-70",
                    "Cemento asfaltico",
                    "Asfalto en caliente",
                    "Asfalto en frio"
                ],
                img: require("@/assets/img/1913_960/49.jpg"),
                img_about: require("@/assets/img/1913_960/50.jpg"),
                gallery: [
                require("@/assets/img/1913_960/51.jpg"),
                require("@/assets/img/1913_960/52.jpg"),
                require("@/assets/img/1913_960/53.jpg"),
                require("@/assets/img/1913_960/55.jpg"),
                ],
                icon: "fas fa-road",
            },
            {
                id: 8,
                title: "Electrificación",
                description: "Mantenimiento, instalación y venta de equipos y materiales para electrificación",
                list: [
                    "Transformadores",
                    "Aparta rayos c/circuitos",
                    "Herrajes",
                    "Preformados",
                    "Aislamientos",
                    "Accesorios subterraneos",
                    "Cables de media tensión XLP",
                    "Flexoducto de alta densidad",
                    "Registros, muretes",
                    "Tapa y aro de hierro ductil",
                    "Concentración de medidores",
                    "Sistemas de tierra",
                    "Pararrayos",
                    "Conectores de linea viva",
                    "Fleje de acero inoxidable",
                    "Herramientas"
                ],
                img: require("@/assets/img/1913_960/59.jpg"),
                img_about: require("@/assets/img/1913_960/56.jpg"),
                gallery: [
                require("@/assets/img/1913_960/57.jpg"),
                require("@/assets/img/1913_960/58.jpg"),
                require("@/assets/img/1913_960/60.jpg"),
                require("@/assets/img/1913_960/61.jpg"),
                ],
                icon: "fas fa-charging-station",
            },
            {
                id: 9,
                title: "Semaforización",
                description: "Diseño y venta de señalamiento y semaforización",
                list: [
                    "Semaforización horizontal",
                    "Semaforización vertical",
                    "Dispositivos diversos",
                    "Ingenieria vial",
                    "Sistemas centralizados e instalación",
                    "Fabricación de señales SR, SP, SIS, SIT, SIR, SIG, SIDSID-14, SID-15",
                    "Defensa metalica y amortiguadores de impacto",
                    "Radares de velocidad"
                ],
                img: require("@/assets/img/1913_960/67.jpg"),
                img_about: require("@/assets/img/1913_960/68.jpg"),
                gallery: [
                require("@/assets/img/1913_960/63.jpg"),
                require("@/assets/img/1913_960/65.jpg"),
                require("@/assets/img/1913_960/66.jpg"),
                require("@/assets/img/1913_960/69.jpg"),
                ],
                icon: "fas fa-traffic-light",
            },
            {
                id: 10,
                title: "Seguridad privada",
                description: "Servicios de personal, equipo y tecnologia para cuidar y garantizar la integridad física y seguridad de su personal, de los bienes y de los inmuebles a su cargo.",
                list: ["Control de personas y vehículos que entran y salen del lugar",
                    "Vigilancia las 24 hrs los 365 dias del año",
                    "Vigilancia y protección de bienes muebles/inmuebles, y las personas que allí se encuentren",
                    "Controlar la identidad en el acceso a determinados inmuebles",
                    "Evitar la comisión de actos delictivos o infracciones, en relación al objeto protegido",
                    "Poner a los delincuentes (en relación al objeto bajo protección) a disposición de las fuerzas y los cuerpos de seguridad. asimismo, los instrumentos, efectos y pruebas del delito"],
                    img: require("@/assets/img/1913_960/72.jpg"),
                img_about: require("@/assets/img/1913_960/75.jpg"),
                gallery: [
                require("@/assets/img/1913_960/73.jpg"),
                require("@/assets/img/1913_960/74.jpg"),
                require("@/assets/img/1913_960/77.jpg"),
                require("@/assets/img/1913_960/78.jpg"),
                ],
                icon: "fas fa-user-shield",
            }
        ],
  }),
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');
@import url("@/assets/css/style.css");

.site-heading,
.site-heading-black {
  margin-bottom: 30px;
  text-align: center;
}
</style>
