<template>
  <v-carousel cycle :height="$vuetify.breakpoint.xs ? '200' : '600'" hide-delimiter-background show-arrows-on-hover>
    <v-carousel-item v-for="(slide, i) in slides" :key="i" >
      <v-sheet :color="colors[i]" :class="clases[i]" :height="$vuetify.breakpoint.xs ? '200' : '100%'" width="100%" >
        <v-row class="fill-height"  align="center" justify="center">
          <!-- <div class="text-h2">{{ slide }} Slide</div> -->
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  data() {
    return {
      clases: ["content-bg-image-1", "content-bg-image-2", "content-bg-image-3", "content-bg-image-4", "content-bg-image-5", "content-bg-image-6", "content-bg-image-7", "content-bg-image-8"],
      colors: ["indigo", "warning", "pink darken-2", "blue", "indigo", "warning", "pink darken-2", "blue"],
      slides: ["First", "Second", "Third", "fourth", "", "", "", ""],
    };
  },
};
</script>

<style scoped>
.content-bg-image-1 {
  background-image: url("@/assets/img/1913_960/9.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-2 {
  background-image: url("@/assets/img/1913_960/14.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-3 {
  background-image: url("@/assets/img/1913_960/79.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-4 {
  background-image: url("@/assets/img/1913_960/23.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-5 {
  background-image: url("@/assets/img/1913_960/28.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-6 {
  background-image: url("@/assets/img/1913_960/1.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-7 {
  background-image: url("@/assets/img/1913_960/31.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
.content-bg-image-8 {
  background-image: url("@/assets/img/1913_960/73.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% auto; /* Resize the background image to cover the entire container */
}
</style>