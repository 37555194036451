<template>
  <div class="container-card-eud">
    <v-hover v-slot="{ hover }">
      <v-card>
        <v-img height="250" width="100%" :aspect-ratio="16/9" :src="imageSrc">
          <v-expand-transition>
            <div v-if="hover"
              class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-body-2 white--text pa-5 text-justify"
              style="height: 100%;">

              {{ description }}
            </div>
          </v-expand-transition>
        </v-img>
        <v-card-text>
          <v-row align="center" class="mx-0">
            <div class="font-weight-bold text-body-1 primary--text ms-4">{{ title }}</div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "CardImage",
  props: {
    imageSrc: null,
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.container-card-eud {
  border-block-start-style: none;
  cursor: pointer;
}

.container-card-eud:hover {
  border-bottom-style: solid;
  border-bottom-color: #f26723;
  border-bottom-width: 3px;
}
</style>