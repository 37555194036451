<template>
  <v-app class="eudania-main">
    <!-- Navbar Start -->
    <v-row no-gutters>
      <v-col cols="12" class="mb-15">
        <v-app-bar color="primary" dark dense fixed prominent>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

          <v-toolbar-title>
            <v-img contain src="@/assets/img/site-logo.png" />
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>mdi-facebook</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-twitter</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-app-bar>
      </v-col>
    </v-row>
    <!-- Navbar End -->

    <!-- Main Start -->
    <v-main>
      <router-view />
    </v-main>
    <!-- Main End -->
    
    <!-- Footer Start -->
    <v-footer>
      <EudaniaFooter />
    </v-footer>
    <!-- Footer End -->

    <!-- Navigation drawer Area Start -->
    <v-navigation-drawer v-show="drawer" v-model="drawer" app fixed temporary>
      <v-card>
        <v-img contain dark height="100%" src="@/assets/img/logo-nav.jpg">

        </v-img>
        <v-divider></v-divider>

        <v-list class="list-nav-text" dense nav two-line>
          <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <v-list-item link to="/">
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-home
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inicio</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/about">
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-information-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Acerca de</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/services">
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-tools
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Servicios</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/contact">
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-face-agent
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Contacto</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-spacer></v-spacer>
      </v-card>


    </v-navigation-drawer>
    <!-- Navigation drawer Area End -->



  </v-app>
</template>
<script>
import { EudaniaFooter } from "@/components";
export default {
  components: { EudaniaFooter },
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700');


#app {
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #363232;
  font-weight: 400;

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}

a:focus {
  outline: none;
}


/*================================================
06 - REPONSIVE MENU CSS
==================================================*/

.main {
  position: relative;
}

.site-logo img {
  width: 210px;
}

.site-logo {
  display: inline-block;
  margin: 24px 0;
}
</style>
